import React from 'react';
import classNames from 'classnames';

import { Link } from 'gatsby';

import PostThumbnail from './PostThumbnail';

const Post = ({ post }) => {
  let hasCategory = '';

  if (post.data.Category) {
    hasCategory = post.data.Category.toLowerCase();
  }

  return (
    <>
      <Link
        to={ post.fields.slug }
        className={ classNames(
          'teaser', {
            [`teaser--${hasCategory}`]: hasCategory,
          },
        ) }
      >
        <div className="teaser__metadata">
          <div className="teaser__metadata__date">{ post.data.Date }</div>
          <div className="teaser__metadata__title">{ post.data.Author[0].data.Name }</div>
          <div>{ post.data.Author[0].data.Specialism }</div>
          <div className="teaser__metadata__passion">{ post.data.Author[0].data.Passion }</div>
        </div>
        <PostThumbnail thumbnail={ post.data.Thumbnail } />
      </Link>
    </>
  );
};

const PostList = ({ posts }) => (
  <div className="list">
    { posts.map(({ node: post }, key) => (
      <div key={ `item-${key * key}` } className="list__item">
        <Post post={ post } />
      </div>
    )) }
  </div>
);

export default PostList;
