import React from 'react';

import Author from './Author';

const AuthorList = ({ authors }) => (
  <div className="list">
    { authors.map(({ node: author }, key) => (
      <div key={ `item-${key * key}` } className="list__item">
        <Author author={ author } />
      </div>
    )) }
  </div>
);

export default AuthorList;
