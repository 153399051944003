import React, { useState } from 'react';

import { graphql } from 'gatsby';

import Toggle from 'react-toggle';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/posts/List';
import AuthorList from '../components/authors/List';
import Hashtag from '../components/Hashtag';

const IndexPage = ({ data }) => {
  const {
    posts: {
      edges: posts,
    },
    authors: {
      edges: authors,
    },
  } = data;

  const [ toggled, setToggled ] = useState(false);
  const toggleChange = e => {
    setToggled(e.target.checked);
  };

  return (
    <Layout pageClass="page--full page--index">
      <SEO
        keywords={ [ ] }
        title="Home"
      />

      <div className="react-toggle__wrapper">
        <Toggle
          defaultChecked={ toggled }
          onChange={ toggleChange }
          icons={ false }
        />
      </div>

      <Hashtag />

      { !toggled
        ? <PostList posts={ posts } />
        : <AuthorList authors={ authors } /> }
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    posts: allAirtable(
      filter: {table: {eq: "Posts"}}, 
      sort: {fields: data___Date, order: DESC}
    ) {
      edges {
        node {
          id
          data {
            Title
            Category
            Author {
              data {
                Name
                Passion
                Specialism
              }
            }
            Date(formatString: "DD.MM.YYYY")
            Thumbnail {
              localFiles {
                extension
                url
                childImageSharp {
                  fluid(maxHeight: 237) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    authors: allAirtable(
      filter: {table: {eq: "Author"}, data: {Disable_page: {ne: true}}},
      sort: {fields: data___Name_sorting, order: ASC}
    ) {
      edges {
        node {
          data {
            Name
            Passion
            Specialism
            Picture {
              localFiles {
                extension
                url
                childImageSharp {
                  fluid(maxHeight: 237) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
