import React from 'react';
import { Link } from 'gatsby';

const Hashtag = ({ currentPage }) => (
  <h1 className="page__title">
    #
    { [ 'look', 'listen', 'learn', 'grow' ].map((category, key) => (
      <Link key={ `item-${key * key}` } className={ `page__title__hash ${currentPage === category ? 'is-active' : ''} page__title__hash--${category}` } to={ `/${category}` }>
        { category.charAt(0).toUpperCase() + category.slice(1) }
      </Link>
    )) }
  </h1>
);

export default Hashtag;
